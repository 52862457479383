<template>
    <div>
        <PageTop1></PageTop1>
        <PageTop></PageTop>
        <div class="v2-g-w1200">

            <div class="category-banner v2-g-flex-row  v2-g-flex-space-between">
                <div class="category">
                  <advertising :videoSummary="videoSummary"/>
                </div>
                <div class="banner">
                    <div class="banner-wrap" v-if="carousels.length > 0">
                        <b-carousel :autoplay="true" :pause-info="false">
                            <b-carousel-item v-for="(item, i) in carousels" :key="i">
                                <section style="height: 273px">
                                    <img :src="item.imageLinksPc" alt class="banner-img" style="width:100%;height:100%"
                                        @mousedown="toProductDetail(item)" />
                                </section>
                            </b-carousel-item>
                        </b-carousel>
                    </div>
                </div>
            </div>
            <div class="myplan zq" ref="myplan" name="myplan">
                <div class=" title v2-g-flex-row  v2-g-flex-space-between tabBox">
                    <div class="displayFlex"><img src="@/assets-v2/images/zuoxian.png" /></div>
                    <div class="tabList displayFlex">
                        <div :class="['tabList-item',currentTab === i ? 'act-tabList-item' : '']" v-for="(item,i) in tabListData" :key="i" @click="changeTab(i)">{{item}}</div>
                    </div>
                    <div class="displayFlex"><img src="@/assets-v2/images/zuoxian.png" /></div>
                </div>
                <div class="product  v2-g-flex-row">
                    <div class="product-wraper" v-for="(item, index) in lstMyPlan" :key="item.productId">
                        <ProductItem :product="item" :detailLeft="index > 0 && (index + 1) % 5 == 0"
                            @showSimilar="showSimilarDlg(item)" @getGoodsList="getGoodsList" />
                    </div>
                    <div style="width: 100%">
                        <Pager :pageInfo="pageInfo" @getdata="getDataByPage"></Pager>
                    </div>
                </div>
            </div>
        </div>
        <Dialog title="同类商品" :show="isShowSimilar" width="1250px" height="80%" @close="(isShowSimilar = false)">
            <SimilarList :id="productAliasCode" :productCode="productCode"></SimilarList>
        </Dialog>

        <!--品牌大促提示-->
        <div v-if="promotionDialog" class="coupon-bg">
            <div
              class="popupPromotion"
              :style="{
                'background-image': `url(${PromotionData.doesItMeetTheSecondaryRebatePoint ? cijieBg : tejiaBg})`,
                'height': PromotionData.doesItMeetTheSecondaryRebatePoint ? '364px' : '368px'
              }">
              <div class="close-coupon" @click.stop="closePromotion">
                  <i class="close-circle iconfont icon-guanbi1"></i>
              </div>

              <!--特价大促内容-->
              <div class="special-sale-content" v-if="!PromotionData.doesItMeetTheSecondaryRebatePoint">
                <!--<div class="activity-title">-->
                <!--  活动{{ PromotionData.activityProductCount | processingOfData }}+-->
                <!--</div>-->
                <!--特价商品数量-->
                <div class="special-sale-content__number the-number-of-items-on-sale">
                  <span>{{ PromotionData.specialOfferProductCount | processingOfData }}</span>
                  <span class="special-sale-content__number__plus" v-if="PromotionData.specialOfferProductCount >= 100">+</span>
                </div>
                <!--红包商品数量-->
                <div class="special-sale-content__number the-number-of-red-envelope-items">
                  <span>{{ PromotionData.redBagProductCount | processingOfData }}</span>
                  <span class="special-sale-content__number__plus" v-if="PromotionData.redBagProductCount >= 100">+</span>
                </div>
                <!--5元以下（胖东来）数量-->
                <div class="special-sale-content__number less-than-5-items">
                  <span>{{ PromotionData.money5Down | processingOfData }}</span>
                  <span class="special-sale-content__number__plus" v-if="PromotionData.money5Down >= 100">+</span>
                </div>
              </div>

                <!--次结立减内容-->
                <div class="content" v-else>
                  <div class="ppt-num" style="position: relative; top: 247px; left: 50px;">
                    <div class="num">{{ PromotionData.activityProductCount }}+</div>
                  </div>
                  <div class="goodsNum" style="position: relative; top: 27px; font-size: 74px; color: #ff4f7a;">
                    {{ PromotionData.ruleMoney }}
                  </div>
                  <div class="goodsNum" style="position: relative; top: 5px; left: 21px; font-size: 60px; color: #ff4f7a;">
                    {{ PromotionData.maxRebate }}
                  </div>
                </div>

                <div
                  class="ppt"
                  :style="{
                    'position': 'absolute',
                    'top': PromotionData.doesItMeetTheSecondaryRebatePoint ? '365px' : '386px',
                    'left': '50%',
                    'transform': 'translate(-50%, 0)'
                  }"
                  @click="toPromotion">
                  <div class="ppt-box animated delay1">
                    <img
                      v-if="PromotionData.doesItMeetTheSecondaryRebatePoint"
                      class="ppt-img"
                      style="width: 260px; height: 54px;"
                      src="https://obs.pujian.com/frontend/app/cart/cijie-bottem.png"
                      mode="aspectFit"
                    />
                    <img class="ppt-img" src="https://obs.pujian.com/frontend/app/coupon/ppdc-btn1.png" v-else />
                  </div>
                </div>
            </div>
        </div>
        <!--视频红包提示-->
        <div v-if="vedioDialog" class="coupon-bg">
            <div class="popupPromotion" style="background-image: url('https://obs.pujian.com/frontend/app/coupon/new-vedio_bg.png');width: 280px;height: 319px">
                <div class="close-coupon c_v" @click.stop="closeVedio">
                    <i class="close-circle iconfont icon-guanbi1"></i>
                </div>
                <div class="content vedio_content">
                    <div class="ppt-num">
                        <div class="line"></div>
                        <div class="num">{{videoSummary.videoCount - videoSummary.totalViewCount}}个视频未看</div>
                        <div class="line"></div>
                    </div>
                    <div class="rangNum bg1" style="margin-top: 3px">
                        <div class="content" style="padding: 10px 22px 0 25px">
                            <div class="le">
                                <div style="font-size:28px">待领</div>
                            </div>
                            <div class="ri" style="display: flex;align-items: flex-end">
                                <span>{{videoSummary.totalToGetAmount}}</span>
                                <div class="unit">元</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ppt" style="margin-top: 120px" @click="toVedioPage">
                    <div class="ppt-box animated delay1">
                        <img class="ppt-img" src="https://obs.pujian.com/frontend/app/coupon/vedio_btn.png"/>
                    </div>
                </div>
            </div>
        </div>
        <!--基药提示-->
        <div v-if="jiyaoDialog" class="coupon-bg">
            <div class="popupPromotion" style="background-image: url('https://obs.pujian.com/frontend/app/coupon/jiyao.png');width: 300px;height: 325px">
                <div class="close-coupon c_v" @click.stop="closeJiyao">
                    <i class="close-circle iconfont icon-guanbi1"></i>
                </div>
                <div class="jy-content">
                  <div class="nums">{{PromotionData.countBdAndCp}}</div>
                  <div class="ppt btn" style="margin-top: 120px" @click="toJayoPage">
                      <div class="ppt-box animated delay1">
                          <img class="ppt-img" src="https://obs.pujian.com/frontend/app/coupon/vedio_btn.png"/>
                      </div>
                  </div>
                </div>
            </div>
        </div>
        <!--优惠卷提示-->
        <div v-if="couponDialog" class="coupon-bg">
            <div class="coupon-dialog">
                <div class="popCoupon_box_content">
                    <div class="title">恭喜！获得{{ couponList[0].couponName == "99-5" ? "5" : "10" }}元优惠券</div>
                    <div class="coupon-list-box-item" v-for="(item, index) in couponList" :key="index">
                        <div class="coupon-list-box-item-le">
                            <div class="item-le-top" v-if="item.couponName == '99-5'">
                                <span>￥<span :class="[item.couponName == '99-5' ? 'tx1' : 'tx']">5</span></span>
                            </div>
                            <div class="item-le-top" v-else>
                                <span>￥<span :class="[item.couponName == '99-5' ? 'tx1' : 'tx']">5</span></span>
                                <span>或</span>
                                <span>￥<span :class="[item.couponName == '99-5' ? 'tx1' : 'tx']">10</span></span>
                            </div>
                            <p class="item-le-bootm" v-if="item.couponName == '99-5'">满99元可用</p>
                            <div class="item-le-bootm-box" v-else>
                                <p class="item-le-bootm">满99元可用5元</p>
                                <p class="item-le-bootm">满199元可用10元</p>
                            </div>
                            <div class="r-box"></div>
                            <div class="r-box1"></div>
                        </div>
                        <div class="coupon-list-box-item-ri">
                            <div class="item-ri-con">
                                <p style="line-height: 41px;margin-bottom: 0;">{{ item.couponRange == 0 ? "全场通用券" : "" }}
                                </p>
                                <p>{{ item.couponEndTime }}后过期</p>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <span class="popCoupon_box_btn" @click.stop="nowUse">立即使用</span>
                <div class="close-coupon" @click.stop="closeCoupon">
                    <div class="close-coupon-txt">优惠券已自动领取，可进入“我的-优惠券”查看</div>
                    <i class="close-circle iconfont icon-guanbi1"></i>
                </div>
            </div>
        </div>
        <!--返利弹窗-->
        <RebateList :show="showRebate" @close="showRebate = false"></RebateList>
        <EarnMoney :show="showEarnMoney" @close="showEarnMoney = false" :styleType="styleType"></EarnMoney>
        <PageBottom></PageBottom>
        <Dialog :show="userArrears" title="欠款提示" width="533px" height="400px" @close="(userArrears = false)">
            <div class="proBox1">
                <div class="proBox-title">
                    <span
                        v-if="arrearsData.days == 0 && arrearsData.haveOtherDebit == 1 && arrearsData.isLock === 0">明天将因欠款无法下单</span>
                    <span v-if="arrearsData.isLock === 1">因欠款无法下单</span>
                </div>
                <div class="proBox-msg">
                    <p>截止当前，<span style="color: #E5432E">您已累计欠款{{ arrearsData.currentSumDebit }}元，</span></p>
                    <p>为不影响您正常下单，请立即还款。</p>
                </div>
                <div class="proBox-btn" @click="nowArrears">
                    <span>立即还款</span>
                </div>
            </div>
        </Dialog>
        <!--红包到账通知-组件-->
        <redPacketNotice />

        <!--过期/临期资质弹窗-->
        <qualificationsDialog ref="qualificationsDialogRef" @continueToAddPurchases="continueToAddPurchases" />
    </div>
</template>
<style scoped lang="less">
.category {
    width: 260px;
}

.special-sale-content{
  position: relative;

  .activity-title{
    position: absolute;
    left: 50%;
    top: 8px;
    transform: translate(-50%);
    font-weight: bold;
    background: -webkit-linear-gradient(180deg, #fff, #FFCF4E); /* Chrome, Safari */
    background: linear-gradient(180deg, #fff, #FFCF4E); /* 标准语法 */
    -webkit-background-clip: text; /* Chrome, Safari */
    background-clip: text;
    -webkit-text-fill-color: transparent; /* Chrome, Safari */
    color: transparent; /* 其他浏览器 */
    font-size: 25px;
  }

  .special-sale-content__number{
    position: absolute;
    left: 120px;
    color: #fff;
    font-weight: bold;
    font-size: 40px;
    width: 140px;
  }

  .the-number-of-items-on-sale {
    top: 68px;
    text-shadow: 0 3px 6px rgba(175, 0, 0, 0.8);
  }

  .the-number-of-red-envelope-items{
    top: 132px;
    text-shadow: 0 3px 6px rgba(175, 42, 0, 0.8);
  }

  .less-than-5-items{
    top: 191px;
    text-shadow: 0 3px 6px rgba(10, 60, 150, 0.8);
  }

  .special-sale-content__number__plus{
    position: absolute;
    top: -2px;
    font-size: 24px;
  }
}

.banner {
    height: 273px;
    width: 960px;
}

.zq {
    .title {
        font-size: 16px;
        font-weight: 400;
        span {
            font-size: 1.75rem;
        }

    }

    .more {
        span {
            width: 67px;
            height: 30px;
            background: #FFFFFF;
            border: 1px solid #1fba68;
            color: #1fba68;
            border-radius: 4px;
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: #26B4E4;
                border: 1px solid #26B4E4;
            }
        }
    }
}

.myplan {
  margin-top: 30px;
  .tabBox{
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #ffffff;
    padding: 20px 0;
    .tabList{
      gap: 22px;
      padding: 0 28px;
      .tabList-item{
        width: 147px;
        height: 48px;
        line-height: 48px;
        background: #F2F2F2;
        border-radius: 8px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.9);
        cursor: pointer;
      }
      .act-tabList-item{
        background: #3875F6;
        color: #ffffff;
      }
    }
  }
}

.rebate {}

.redbag {}

.product {
    flex-wrap: wrap;
    justify-content: flext-start;

    .product-wraper {
        margin-right: 17.5px;
        margin-bottom: 24px;
    }

    :nth-child(5n+5) {
        margin-right: 0;
    }

}

.coupon-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0 / 60%);
    z-index: 99999;
}

.popupPromotion {
    width: 340px;
    height: 324px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3000;

    .content {
        padding: 58px 60.5px;

        .ppt-num {
            display: flex;
            align-items: center;
            justify-content: center;

            .line {
                width: 23px;
                height: 1px;
                background-color: #801B15;
            }

            .num {
                font-size: 22px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #801B15;
                margin: 0 6px;
            }
        }

        .goodsNum {
            font-size: 23px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #FF3E33;
            text-align: center;
            margin-top: 3px;
        }

        .rangNum {
            width: 100%;
            height: 82px;
            background-image: url("https://obs.pujian.com/frontend/app/coupon/ppdc-item.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: relative;

            .content {
                padding: 0 28px 0 23px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .le {
                    div {
                        font-size: 19px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        font-weight: bold;
                        color: #D96003;
                    }
                }

                .ri {
                    color: rgba(255, 62, 51, 1);

                    span:first-child {
                        font-size: 49px;
                        font-weight: bold;
                    }

                    span:last-child {
                        font-size: 22px;
                    }
                    .unit{
                        width: 26px;
                        height: 26px;
                        line-height: 23px;
                        text-align: center;
                        background: #FF3E33;
                        opacity: 1;
                        border: 1px solid #FFFFFF;
                        border-radius: 50%;
                        font-size: 15px;
                        color: #ffffff;
                        font-weight: bold;
                        margin-left: 4px;
                    }
                }
            }
        }

        .bg1{
            height: 109px;
            background-image: url('https://obs.pujian.com/frontend/app/coupon/vedio_des.png');
        }
    }

    .jy-content{
      width: 100%;
      height: 100%;
      position: relative;
      .nums{
        width: 145px;
        height: 87px;
        font-size: 57px;
        background-image: linear-gradient(180deg, #FF91C1 0%, #FF014F 100%);
        color: transparent;
        background-clip: span;
        font-weight: bold;
        position: absolute;
        left: 33px;
        top: 45%;
        line-height: 87px;
        text-align: center;
      }
      .btn{
        margin-top: 0 !important;
        position: absolute;
        bottom: -55px;
        left: 45px;
      }
    }
    .vedio_content{
        padding: 70px 24px 0 26px;
        .title{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 6px;
        }
    }
    .ppt{
        cursor: pointer;

        .ppt-box {
            -webkit-animation-name: scaleDraw;
            /*关键帧名称*/
            animation-timing-function: linear;
            /*动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；*/
            -webkit-animation-timing-function: linear;
            /*动画的速度曲线*/
            -webkit-animation-iteration-count: infinite;
            /*动画播放的次数*/
            -webkit-animation-duration: 1s;

            /*动画所花费的时间*/
            .ppt-img {
                width: 207px;
                height: 49px;
                margin: 0 auto;
            }
        }
    }

    .close-coupon {
        position: absolute;
        right: 0;
        top: -40px;
        color: #fff;
        text-align: center;
        cursor: pointer;

        i {
            font-size: 36px;
        }
    }
    .c_v{
        right: -30px;
        top: -50px;
    }
}
.coupon-dialog {
    width: 280px;
    height: 393px;
    background-image: url("https://obs.pujian.com/frontend/app/coupon/coupon_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3000;

    .close-coupon {
        position: absolute;
        bottom: -80px;
        color: #fff;
        text-align: center;

        .close-coupon-txt {
            font-size: 13px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 8px;
            margin-bottom: 12px;
        }

        .close-circle {
            cursor: pointer;
            color: #fff;
            font-size: 20px;
        }
    }

    .popCoupon_box_content {
        width: 280px;
        padding: 0 10px;
        position: absolute;
        bottom: 96px;

        .title {
            font-size: 18px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #FFFDE6;
            text-align: center;
            margin-bottom: 16px;
        }

        .coupon-list-box-item {
            margin-top: 20px;
            display: flex;

            .coupon-list-box-item-le {
                width: 110px;
                height: 84px;
                background: linear-gradient(181deg, #FFBA6B 0%, #FF5741 100%);
                border-radius: 12px 0 0 12px;
                opacity: 1;
                position: relative;

                .item-le-top {
                    text-align: center;
                    font-size: 14px;
                    color: #ffffff;
                    font-weight: bold;

                    .tx {
                        font-size: 33px;
                    }

                    .tx1 {
                        font-size: 37px;
                    }
                }

                .item-le-bootm-box {
                    text-align: left;
                    margin-left: 6px;

                    .item-le-bootm {
                        font-size: 12px;
                    }
                }

                .item-le-bootm {
                    font-size: 12px;
                    font-weight: 400;
                    color: #FFFFFF;
                }

                .r-box {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background: #ff153e;
                    position: absolute;
                    top: -8px;
                    right: -8px;
                }

                .r-box1 {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background: #ff153e;
                    position: absolute;
                    bottom: -8px;
                    right: -8px;
                }
            }

            .coupon-list-box-item-ri {
                width: 180px;
                height: 84px;
                background: #FFF3EC;
                border-radius: 0 12px 12px 0;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: space-around;

                .item-ri-con {
                    text-align: left;

                    p:first-child {
                        font-size: 16px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.902);
                        margin-bottom: 10px;
                    }

                    p:last-child {
                        font-size: 12px;
                        font-weight: 400;
                        color: #999999;
                    }
                }

                .item-ri-btn {
                    cursor: pointer;
                    width: 88px;
                    height: 29px;
                    line-height: 29px;
                    background: linear-gradient(0deg, #F2533E 0%, #F3B369 100%);
                    border-radius: 12px 12px 12px 12px;
                    opacity: 1;
                    filter: blur(undefinedpx);
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }

                .coupon-img {
                    width: 90px;
                    height: 90px;
                }
            }
        }
    }

    .popCoupon_box_btn {
        position: absolute;
        bottom: 45.5px;
        left: 105px;
        font-size: 20px;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #8C1F05;
        cursor: pointer;
    }

    .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both
    }

    .delay1 {
        -webkit-animation-delay: 0.6s; //延迟执行动画
        animation-delay: 0.6s;
    }

    @keyframes scaleDraw {

        /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
        0% {
            transform: scale(1);
            /*开始为原始大小*/
        }

        25% {
            transform: scale(0.9);
            /*放大1.1倍*/
        }

        50% {
            transform: scale(0.85);
        }

        75% {
            transform: scale(0.9);
        }

        100% {
            transform: scale(1);
        }
    }
}
.proBox-title{
      font-size: 24px;
      color: #222222;
      display: flex;
      align-items: center;
      font-weight: bold;
      img{
        width: 17px;
        height: 27px;
      }
    }
    .proBox-msg{
      font-size: 20px;
      font-weight: 500;
      color: #222222;
      padding: 0 57px;
      margin-top: 32px;
    }
    .proBox-btn{
      width: 170px;
      height: 45px;
      background: #2979FF;
      border-radius: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      margin: 0 auto;
      margin-top: 109px;
      cursor: pointer;
    }
    .category-banner{
      margin-top: 12px;
    }
</style>

<script>
import PageTop from "@/components-v2/layout/page-top"
import PageTop1 from "@/components-v2/layout/page-top1"
import PageBottom from "@/components-v2/layout/page-bottom"
import ProductItem from "@/components-v2/product/product-item";
import Dialog from '@/components-v2/v2-dialog/index'
import SimilarList from '@/components-v2/product/similar-product-list'
import RebateList from '@/components-v2/rebate/index'
import EarnMoney from "@/components-v2/earn-money-show/index.vue"
import redPacketNotice from '@/components/red-packet-notice'
import qualificationsDialog from '@/components-v2/qualificationsDialog.vue'
import publicMethods from '@mixin/publicMethods'
import qualificationsPopup from '@mixin/qualificationsPopup'
import advertising from "@/components-v2/advertising/index.vue";
import { AccountStorage } from "@services/account";
import Pager from "@/components-v2/pager/index.vue";
const accountStorage = new AccountStorage();

export default {
    data() {
        return {
            lstMyPlan: [],  //我的计划
            lstRebate: [], //返利
            lstRedbag: [], //红包
            carousels: [], //banner
            isShowSimilar: false,//是否显示相识商品
            productAliasCode: "",
            couponDialog: false, //优惠卷提示
            promotionDialog:false,//品牌大促提示
            vedioDialog:false,//视频红包提示
            jiyaoDialog:false,//基药弹窗
            couponList:[],
            showRebate: false, //是否显示返利对话矿
            showEarnMoney: false,
            PromotionData: {},
            userArrears: false,//欠款提示
            arrearsData: {},//欠款信息
            videoSummary:{
                videoCount: 6,
                videoViewCount: 1000,
                totalToGetAmount: 0,
                totalGettedAmount: 0,
                lstVideo: []
            },
            styleType:0,
            cijieBg: 'https://obs.pujian.com/frontend/app/cart/cijie-bg.png',
            tejiaBg: 'https://obs.pujian.com/frontend/app/cart/tejia-bg1.png',
            // 弹窗数据
            popupData: [],
            productCode: '',
            tabListData:['我的计划','返利专区','新品红包'],
            currentTab:0,
            isScroll:false
        };
    },
    components: {
        Pager,
        PageTop,
        PageTop1,
        PageBottom,
        ProductItem,
        Dialog,
        SimilarList,
        RebateList,
        EarnMoney,
        redPacketNotice,
        qualificationsDialog,
        advertising
    },
    mixins: [publicMethods, qualificationsPopup],
    filters: {
      processingOfData(val) {
        if (!val) return '';
        if (val < 100) return val;
        return Math.floor(val / 100) * 100;
      }
    },
    created() {
        this.getGoodsList()
        this.getBannerList();
        // this.getCoupon();
        this.wilExpireList()
    },
    beforeRouteEnter(to, from, next) {
        // 登录后或首页
        if (from.path === '/v2/login' || from.path === '/') {
            next(vm => {
                vm.getVideoSummary();
            })
        } else {
            next()
        }
    },
    methods: {
        getDataByPage(e){
            this.getCommonProductList({ page: e, pageSize: 20 }, this.currentTab === 0 ? '我的计划' : this.currentTab === 1 ? '返利专区' : '新品红包',true);
            this.isScroll = true;
        },
        changeTab(i){
            this.currentTab = i;
            this.getCommonProductList({ page: 1, pageSize: 20 }, i === 0 ? '我的计划' : i === 1 ? '返利专区' : '新品红包',true);
            this.isScroll = true;
        },
        getGoodsList() {
          this.getCommonProductList({ page: 1, pageSize: 20 }, '我的计划',true);
        },
        //获取视频汇总信息
        async getVideoSummary() {
            let api = `/pjyy-deepexi-market-center/api/v1/up/mobile/video/getVideoTotalInfo`;
            const res = await this.$getHttpClient().get(api, { "headers": { "TokenType": 'customer' } })
            if (res.data.code == 200) {
                this.videoSummary = res.data.data || {};
                this.queryPopupCustomerCode()
            }
        },
        //获取品牌大促
        async getPromotionStat() {
            let api = "/pjyy-deepexi-product-center/api/v3/product/PcProductCommon/promotionStat";
            const res = await this.$getHttpClient().get(api);
            if (res.data.code == 200) {
              this.handlePopUpLogic(res)
            }
        },
        // 处理弹窗逻辑
        handlePopUpLogic(res) {
          let latformPC = this.popupData.filter(item => item.platformType === "PC")
          if (res.data.data.doesItMeetTheSecondaryRebatePoint) {  // 次结弹窗 secondaryKnotPopup
            if (latformPC.length == 0) {
              this.PromotionData = res.data.data;
              this.promotionDialog = true
              this.popupData.push({
                platformType: "PC",
                popupType: "secondaryKnotPopup"
              })
              this.savePopCustomerCode(this.popupData)
            } else {
              let popupTypeArr = latformPC.filter(item => item.popupType === 'secondaryKnotPopup');
              if (popupTypeArr.length == 0) {
                this.PromotionData = res.data.data;
                this.promotionDialog = true
                this.popupData.push({
                  platformType: "PC",
                  popupType: "secondaryKnotPopup"
                })
                this.savePopCustomerCode(this.popupData)
              }
            }
          } else {
            let date = new Date();
            let days = date.getDate();
            let lastDate = this.getLastMonthDate();
            if (lastDate == 31) {
              if (days % 2 === 0) {
                //偶数 正常弹特价，如果没有特价就弹视频红包
                this.specialPriceBeforeTheVideoPopUpWindow(res)
              } else {
                //奇数 正常弹视频红包，如果可领红包等于0则弹特价
                this.firstVideoThenSpecialPopUpWindow(res)
              }
            } else {
              if (days % 2 === 0) {
                //偶数 正常弹视频红包，如果可领红包等于0则弹特价
                this.firstVideoThenSpecialPopUpWindow(res)
              } else {
                //奇数 正常弹特价，如果没有特价就弹视频红包
                this.specialPriceBeforeTheVideoPopUpWindow(res)
              }
            }
          }
        },
        // 先特价后视频弹窗
        specialPriceBeforeTheVideoPopUpWindow(res) {
          if (res.data.data && res.data.data.specialOfferProductCount > 0) {  // 特价弹窗 specialPricePopup
            this.showSpecialPricePopup(res)
          } else if (this.videoSummary.totalToGetAmount > 0) {  // 视频弹窗 videoPopup
            this.showVideoPopup()
          }
        },
        // 先视频后特价弹窗
        firstVideoThenSpecialPopUpWindow(res) {
          if (this.videoSummary.totalToGetAmount > 0) {  // 视频弹窗 videoPopup
            this.showVideoPopup()
          } else if (res.data.data && res.data.data.specialOfferProductCount > 0) {  // 特价弹窗 specialPricePopup
            this.showSpecialPricePopup(res)
          }
        },
        // 显示特价弹窗
        showSpecialPricePopup(res) {
          let latformPC = this.popupData.filter(item => item.platformType === "PC")
          if (latformPC.length == 0) {
            this.PromotionData = res.data.data;
            this.promotionDialog = true
            this.popupData.push({
              platformType: "PC",
              popupType: "specialPricePopup"
            })
            this.savePopCustomerCode(this.popupData)
          } else {
            let popupTypeArr = latformPC.filter(item => item.popupType === 'specialPricePopup');
            if (popupTypeArr.length == 0) {
              this.PromotionData = res.data.data;
              this.promotionDialog = true
              this.popupData.push({
                platformType: "PC",
                popupType: "specialPricePopup"
              })
              this.savePopCustomerCode(this.popupData)
            }
          }
        },
        // 显示视频弹窗
        showVideoPopup() {
          let latformPC = this.popupData.filter(item => item.platformType === "PC")
          if (latformPC.length == 0) {
            this.vedioDialog = true
            this.popupData.push({
              platformType: "PC",
              popupType: "videoPopup"
            })
            this.savePopCustomerCode(this.popupData)
          } else {
            let popupTypeArr = latformPC.filter(item => item.popupType === 'videoPopup');
            if (popupTypeArr.length == 0) {
              this.vedioDialog = true
              this.popupData.push({
                platformType: "PC",
                popupType: "videoPopup"
              })
              this.savePopCustomerCode(this.popupData)
            }
          }
        },
        // 所有的营销弹窗1天只弹1次（欠款、次结、特价、视频)
        async queryPopupCustomerCode() {
          if (accountStorage.getCacheUserInfo()) {
            let account = accountStorage.getCacheUserInfo().account;
            let url = `/pjyy-deepexi-user-center/api/v1/customer/queryPopByCustomerCode/${account}`;
            const res = await this.$getHttpClient().get(url);
            let { code, data } = res.data;
            if (code == 200) {
              if (data) this.popupData = JSON.parse(data);
              this.getArrears()
            }
          }
        },
        // 保存弹窗的记录
        savePopCustomerCode(popupData) {
          let params = {
            customerCode: accountStorage.getCacheUserInfo().account,
            saveInfo: ''
          }
          if (popupData) params.saveInfo = JSON.stringify(popupData);
          let url = '/pjyy-deepexi-user-center/api/v1/customer/savePopByCustomerCode';
          this.$getHttpClient({ useSpinner: false }).post(url, params);
        },
        //获取上月最后一天
        getLastMonthDate() {
            let nowdays = new Date()
            let year = nowdays.getFullYear()
            let month = nowdays.getMonth()
            if (month == 0) {
                month = 12
                year = year - 1
            }
            if (month < 10) {
                month = '0' + month
            }
            let myDate = new Date(year, month, 0)
            // let startDate = year + '-' + month + '-01 00:00:00' //上个月第一天
            // let endDate = year + '-' + month + '-' + myDate.getDate() //上个月最后一天
            return myDate.getDate()
        },
        //关闭视频红包
        closeVedio(){
            this.vedioDialog = false
        },
        //关闭基药弹窗
        closeJiyao(){
            this.jiyaoDialog = false
        },
        //跳转视频红包
        toVedioPage(){
            this.$router.push({ name: 'v2-video' })
        },
        //跳转基药专区
        toJayoPage(){
            this.$router.push({ name: 'v2-product', params: { id: '9' } })
        },
        //获取欠款
        async getArrears() {
            let account = accountStorage.getCacheUserInfo().account;
            const URL = `/pjyy-deepexi-erp-api-center/api/v1/erp/creditStanding/${account}`;
            const result = await this.$getHttpClient().get(URL);
            if (result.data.code == 200) {
              this.arrearsData = result.data.data;

              // 欠款弹窗逻辑  arrearsPopup
              if (!this.arrearsData.cashType && (this.arrearsData.days == 0 || this.arrearsData.isLock === 1)) {
                let latformPC = this.popupData.filter(item => item.platformType === "PC")
                if (latformPC.length == 0) {
                  this.userArrears = true;
                  this.popupData.push({
                    platformType: "PC",
                    popupType: "arrearsPopup"
                  })
                  this.savePopCustomerCode(this.popupData)
                } else {
                  let popupTypeArr = latformPC.filter(item => item.popupType === 'arrearsPopup');
                  if (popupTypeArr.length == 0) {
                    this.userArrears = true;
                    this.popupData.push({
                      platformType: "PC",
                      popupType: "arrearsPopup"
                    })
                    this.savePopCustomerCode(this.popupData)
                  }
                }
              }
            }
            // 没有欠款时，再请求特价大促功能
            if (!this.userArrears) {
              let latformPC = this.popupData.filter(item => item.platformType === "PC")
              let popupTypeArr = latformPC.filter(item => item.popupType === 'secondaryKnotPopup' || item.popupType === 'specialPricePopup' || item.popupType === 'videoPopup');
              // 没有弹过特价、次结、视频红包弹窗
              if (popupTypeArr.length == 0) {
                this.getPromotionStat()
              }
            }
        },
        //立即还款
        async nowArrears() {
            let account = accountStorage.getCacheUserInfo().account;
            const URL = `/pjyy-deepexi-pay-center/rpc/v2/openPay/getWebPayLink1?erpId=${account}&orderSource=5`;
            const result = await this.$getHttpClient().get(URL);
            if (result.data.code == 200) {
                window.open(result.data.data)
            }
        },
        //跳转品牌大促
        toPromotion() {
            this.$router.push({ name: 'v2-hotProduct' })
        },
        closeCoupon() {
            this.couponDialog = false;
        },
        //关闭品牌大促
        closePromotion() {
            this.promotionDialog = false
        },
        //获取列表
        async getCoupon() {
            const res = await this.$getHttpClient().get("/pjyy-deepexi-coupon-center/api/v1/queryCouponByCustomerCode");
            if (res.data.code == 200) {
                if (res.data.data && res.data.data.length > 0) {
                    this.couponList = res.data.data;
                    this.couponDialog = true
                }
            }
        },

        toProductDetail(item) {
            if (item.pcUrl && item.pcUrl.indexOf('newActivity') >= 0) {
                this.styleType = 0;
                this.showEarnMoney = true;
                return;
            }
            if (item.pcUrl && item.pcUrl.indexOf('yaojianju') >= 0) {
                this.styleType = 1;
                this.showEarnMoney = true;
                return;
            }
            //兼容旧版本,旧版本pc地址配置的activity
            if (item.pcUrl && item.pcUrl.indexOf('activity') >= 0) {
                this.$router.push({ name: 'v2-activity', params: { id: item.id } })
                return;
            }
            //跳转到专区
            if (item.pcUrl) {
                if (!item.pcUrl.startsWith('http')) {
                    this.$router.push({ path: item.pcUrl, query: { activityId: item.id } })
                } else {
                    window.open(item.pcUrl)
                }
            }

        },
        //立即使用
        nowUse() {
            this.jumpto('2') //跳转到返利
            this.couponDialog = false;
        },
        jumpto(code) {
            if (code == '1') {
                this.$router.push({ name: 'v2-plan' });
                return;
            }
            if (code == '2') {
                this.showRebate = true;
                return;
            }
            this.$router.push({ name: 'v2-product', params: { id: code } })
        },
        showSimilarDlg(item) {
            this.productAliasCode = item.productAliasCode.toString();
            this.productCode = item.productCode;
            this.isShowSimilar = true;
        },
        // 主广告图
        getBannerList() {
            // 主广告
            const getBannerList = "/pjyy-deepexi-aggregation/api/v1/advertising/findByCondition";
            return this.$getHttpClient()
                .get(getBannerList)
                .then(res => {
                    let result = res.data ? res.data.data : [];
                    if (result) {
                        this.carousels = res.data ? res.data.data : [];
                    }
                    return res;
                });
        }
    }

}
</script>
