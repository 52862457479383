<template>
  <div>
    <div :class="['col_item', sIndex == 1 ? 'add_right' : '']" v-for="(secondAd, sIndex) in showModalList"
         :key="sIndex" @click="adNavigateTo(secondAd.url, secondAd)">
      <div v-if="secondAd.url.indexOf('vedios/vedioList') >= 0" class="video-wrapper">
        <div class="v-content">
          <div class="line1">
            <div class="left">看视频</div>
            <div class="t-bg v-bg">
              <div class="center v">返</div>
              <div class="right">
                  红包
              </div>
            </div>
          </div>
          <div class="displayFlex" style="padding-top: 16px">
              <div class="left-content v">
                  <div class="line2 v">
                      <div>
                <span class="nums">
                    {{ Math.floor(videoSummary.videoViewCount / 100) * 100 + 100 }}
                </span>观看
                      </div>
                      <div class="num" :class="{ 'active': isVideoPlus }">+1</div>
                  </div>
                  <div class="line3 v">
                      可领取
                      <span class="nums">￥{{ videoSummary.totalToGetAmount }}</span>
                  </div>
              </div>
              <div class="bb">
                  <el-carousel height="70px" :interval="4000" indicator-position="none" arrow="never">
                      <el-carousel-item v-for="(item,i) in videoSummary.lstVideo" :key="i">
                          <img class="img" src="https://obs.pujian.com/frontend/app/other/play_btn.png"/>
                          <img :src="item.coverImgageUrl" />
                      </el-carousel-item>
                  </el-carousel>
              </div>
          </div>
        </div>
      </div>

      <div v-else-if="secondAd.url.indexOf('product/zqlist') >= 0" class="video-wrapper">
        <div class="v-content">
          <div class="line1">
            <div class="left">特价商品</div>
            <div class="t-bg">
              <div class="center">立减</div>
              <div class="right">5%-60%</div>
            </div>
          </div>
          <div class="displayFlex" style="padding-top: 16px">
            <div class="left-content" style="width: auto">
              <div class="line2">
              <span class="nums">
                <span v-if="hotSummary.totalActCount > 100">{{
                    Math.floor(hotSummary.totalActCount / 100) * 100 + 100
                  }}</span>
                <span v-if="hotSummary.totalActCount < 100">100</span>
                <span style="position: relative; top: -5px;">+</span>
              </span>
                活动
              </div>
              <div class="line3">
              <span class="nums">
                  {{ Math.floor(hotSummary.totalBuyCount / 100) * 100 + 100 }}
              </span>人购买
                <div class="num" :class="{ 'active': isHotProductPlus }">+1</div>
              </div>
            </div>
            <div class="bb bb1">
              <el-carousel height="70px" :interval="4000" indicator-position="none" arrow="never">
                <el-carousel-item v-for="(item,i) in hotSummary.lstImg" :key="i">
                  <img :src="item"/>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
      <img v-else width="100%" height="74px" :src="secondAd.imageLink"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props:{
      videoSummary:{
          type: Object,
          default: {},
      }
  },
  data() {
    return {
      secondAdList: [],
      hotSummary: {
        totalCount: 7000,
        totalActCount: 4124,
        totalBuyCount: 209874,
        lstImg: []
      },
      isHotProductPlus: false,//爆款是否显示＋１效果
      isVideoPlus: false,//视频是否显示+1效果
    }
  },
  created() {
    this.getHotSummary();
  },
  methods: {
    async getHotSummary() {
      let api = `/pjyy-deepexi-product-center/api/v3/product/PcProductCommon/getHotStat`;
      const res = await this.$getHttpClient({
        useSpinner: false,
      }).get(api)
      if (res.data.code == 200) {
        this.hotSummary = res.data.data
      }
    },
    randomAddHotBuyCount() {
      let $this = this;
      let rd = Math.floor(Math.random() * 10 + 1) % 5;
      let rd1 = Math.floor(Math.random() * 10 + 2);
      let timer =
          setTimeout(() => {
            $this.isHotProductPlus = true;
            $this.hotSummary.totalBuyCount = $this.hotSummary.totalBuyCount + 1;
            timer && clearTimeout(timer);
            let timer1 = setTimeout(() => {
              $this.isHotProductPlus = false;
              timer1 && clearTimeout(timer1)
            }, 1000)
          }, rd * 1000)

      let timer_v =
          setTimeout(() => {
            $this.isVideoPlus = true;
            $this.videoSummary.videoViewCount = $this.videoSummary.videoViewCount + 1;
            timer_v && clearTimeout(timer_v);
            let timer1 = setTimeout(() => {
              $this.isVideoPlus = false;
              timer1 && clearTimeout(timer1)
            }, 1000)
          }, rd1 * 1000)
    },
    // 广告位
    adNavigateTo(url, item) {
      //埋点
      this.advertising(item)
      // 链接类型（linkType） 1.指定页面  2.专题活动  3.外部链接
      if (item.linkType == 3) {
        window.location.href = url;
      } else {
        if (url === "/pages2/index/vedios/vedioList") {
          this.$router.push({name: 'v2-video'})
        } else {
          this.$router.push({name: 'v2-hotProduct'})
        }
      }
    },
    advertising(data) {
      if (process.env.VUE_APP_ENV !== 'PRD') return;
      //点击广告位埋点
      let dotData = {
        "clientType": uni.getSystemInfoSync().platform,
        // #ifdef APP-PLUS
        "version": plus.runtime.version,
        // #endif
        //#ifdef H5
        "version": "1.1",
        //#endif
        //#ifdef MP-WEIXIN
        "version": uni.getSystemInfoSync().version,
        //#endif
        "component": "appadvertising",
        "advertisingId": data.id,
        "title": data.title,
        "imageLink": data.imageLink,
        "putArea": data.putArea
      }
      this.$getHttpClient({
        useSpinner: false,
      }).post(
          "/pjyy-deepexi-collect-center/api/v1/collect", {
            body: JSON.stringify(dotData)
          }
      )
    }
  },
  mounted() {
    this.randomAddHotBuyCount()
  },
  computed: {
    showModalList() {
      return this.secondAdList.length == 0 ? [
        {
          "id": "3",
          "title": "品牌大促",
          "content": "品牌大促",
          "code": "",
          "imageLink": "https://obs.pujian.com/20230213/83acfa82ff8743febf51ed389e43d136.png",
          "url": "/pages2/product/zqlist?code=1015&name=特价大促&isAppletNavigate=true",
          "urlType": 2,
          "linkType": 1,
          "keywords": "",
          "putArea": "2"
        },
        {
          "id": "2",
          "title": "视频红包",
          "content": "视频红包",
          "code": "",
          "imageLink": "https://obs.pujian.com/20230417/311609a0b68f478a86ee498e0d5c1411.png",
          "url": "/pages2/index/vedios/vedioList",
          "urlType": 2,
          "linkType": 1,
          "keywords": "",
          "putArea": "2"
        }
      ] : this.secondAdList
    }
  }
}
</script>

<style scoped lang="less">
.col_item {
  padding: 0 6px !important;
  flex: 1;
  text-align: right;
  cursor: pointer;
}

.video-wrapper {
  width: 240px;
  height: 131px;
  background: linear-gradient(180deg, #FEFEFE 0%, #FFEDED 100%);
  border-radius: 8px;
  opacity: 1;
  font-family: Source Han Sans CN;
  position: relative;
  border: 1px solid #EFEFEF;
  margin-bottom: 10px;

  .bb {
    //position: absolute;
    //right: 8px;
    //bottom: 8px;
    width: 118px;
    height: 70px;
    border-radius: 4px;
    margin-left: 17px;

    .img {
      z-index: 1;
      position: absolute;
      width: 15px;
      height: 15px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    img{
      border-radius: 4px;
    }

    &.bb1 {
      width: 83px;
      margin-left: 14px;
    }
  }

  .v-content {
    padding: 11px;

    .line1 {
      display: flex;
      align-items: center;

      .left {
        font-size: 19px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.902);
      }

      .t-bg {
        display: flex;
        align-items: center;
        margin-left: 13px;
        height: 23px;
        background: #FEEFF0;
        border-radius: 12px;
        padding: 0 9px 0 0;
        width: 126px;
      }

      .v-bg {
        width: 146px;
      }

      .center {
        width: 50px;
        height: 23px;
        line-height: 23px;
        background: linear-gradient(180deg, #FF795E 0%, #FF4465 100%);
        border-radius: 12px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;

        &.v {
          width: 36px;
          background: linear-gradient(180deg, #FF57C7 0%, #FF2C73 100%);
        }
      }

      .right {
        font-weight: bold;
        color: #E5432E;
        font-size: 14px;
        margin-left: 6px;
        position: relative;

        .num {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 12px;
          transform: translate(120%, -20%);
        }
      }
    }

    .left-content {
      width: 45%;
      margin-left: 15px;
      height: 49px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.v {
        margin-left: 0;
      }
    }

    .line3 {
      margin-top: 8px;
    }

    .line2,
    .line3 {
      width: fit-content;
      height: 18px;
      line-height: 18px;

      &.v {
        .num {
          transform: translate(40%, -80%);
        }
      }

      color: #323233;
      text-align: left;
      position: relative;

      .nums {
        font-weight: bold;
        color: #E5432E;
        font-size: 17px;
      }

      .num {
        position: absolute;
        color: #E5432E;
        top: 0;
        right: 0;
        font-size: 15px;
        opacity: 0;
        transform: translate(40%, -40%);

        &.active {
          animation: move1 1s forwards;
        }
      }

      @keyframes move1 {
        0% {
          opacity: 1;
        }

        30% {
          opacity: 1;
        }

        70% {
          opacity: 0.5;
        }

        100% {
          transform: translate(40%, -200%);
          opacity: 0;
        }
      }
    }
  }
}
</style>
